
import { defineComponent } from "vue";
import SubCatalogueGrid from "@/components/controls/catalogueGrid/SubCatalogueGrid.vue";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import { CatalogueColumnConfig } from "@/components/controls/catalogueGrid/columns/catalogueColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import transportContainerRequisiteCreateEditDialog from "./TransportContainerRequisiteCreateEditDialog.vue";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { EnumColumnConfig } from "@/components/controls/catalogueGrid/columns/enumColumnConfig";

export default defineComponent({
  components: {
    SubCatalogueGrid,
  },
  props: {
    parentModel: { required: true },
  },
  methods: {},
  computed: {
    transportContainerRequisiteCreateEditDialog(): any {
      return transportContainerRequisiteCreateEditDialog;
    },
  },
  data() {
    return {
      columns: [
        new CatalogueColumnConfig({
          dataField: "requisiteDescription",
          caption: "Реквизит",
          modelIdField: "requisiteId",
          modelDescField: "requisiteDescription",
          keyField: "id",
          displayField: "requisiteDescription",
          displayDescField: "name",
          catalogueComponentName:
            "catalogues/RequisiteCatalogue/RequisiteCatalogue",
          descriptionColumnName: "requisiteDescription",
          validationRules: [requiredRule],
        }),
        new EnumColumnConfig({
          dataField: "requisiteTypeDescription",
          caption: "Тип",
          enumType: "requisiteType",
          modelField: "requisiteType",
          modelDescField: "requisiteTypeDescription",
          descriptionColumnName: "requisiteTypeDescription",
          validationRules: [requiredRule],
        }),
        new DecimalColumnConfig({
          dataField: "totalWeight",
          caption: "Общий вес (кг)",
          min: 0,
          decimalPoints: 2,
          validationRules: [requiredRule],
        }),
        new BooleanColumnConfig({
          dataField: "isActive",
          caption: "Активный",
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        height: "400px",
        pagingMode: "scroll",
        heightScroll: "400px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowExport: false,
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
      }),
    };
  },
});
